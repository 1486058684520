import { Grid, Link } from '@mui/material';
import FACEBOOK_ICON from 'assests/images/facebook.svg';
import INSTAGRAM_ICON from 'assests/images/instagram.svg';
import TWITTER_ICON from 'assests/images/twitter.svg';

export function TermsAndConditions() {
  return (
    <>
      <h2 style={{ color: '#0557a9', marginBottom: 0, fontSize: '32px' }}>
        الشروط والأحكام
      </h2>
      <p>
        <strong style={{ fontSize: '24px', marginBottom: '12px' }}>
          التعريفات
        </strong>
        <br />
        <strong>منصة بنكنوت:</strong> منصة إلكترونية لعمليات البيع والشراء، تجمع
        بين المشتري الراغب بشراء سلعة او طلب خدمة مع مقدم الخدمة أو البائع
        <br />
        تحرص المنصة على تقديم تجربة بيع وشراء سلسة وآمنة لجميع الأطراف
        <br />
        تتيح المنصة امكانية فتح حساب لنشر الإعلان الخاص بالبائع وفق الأنظمة
        والتشريعات، ويشار إليها بهذه الاتفاقية باسم شركة ورقة نقدية لتقنية
        المعلومات أو "التطبيق" أو " بنكنوت" أو" نحن" أو "المنصة" أو "الموقع
        الالكتروني"
        <br />
        <strong>المستخدمين:</strong> مستخدمي المنصة سواء بائعين او مشتريين أو كل
        من قام بالتسجيل على المنصة أو التطبيق وقد يشار اليهم بـ "العملاء".
        <br />
        <strong>المشتري:</strong> المستخدم الذي يقوم باستخدام المنصة لغرض شراء
        المنتجات أو السلع او طلب الخدمات المعروضة وتصفح الإعلانات وقد يشار اليه
        بـ "العميل" أو "طالب الخدمة".
        <br />
        <strong>البائع:</strong> المستخدم الذي يقوم بنشر الإعلان او بيع المنتجات
        او تقديم الخدمات عن طريق المنصة وقد يشار اليه بـ "مزود الخدمة".
        <br />
        <strong>خدمة الضمان:</strong> خدمة تقدمها منصة بنكنوت عند عملية الدفع،
        لتوفير وساطة مالية آمنه بين الأطراف
        <br />
        تقوم خدمة الضمان بتعليق المبلغ المدفوع من قبل المشتري الى حين استلام
        السلعة أو الحصول على الخدمة وفق المعايير المتفق عليها بين أطراف الصفقة
        <br />
        خدمة بنكنوت مقدمة لتوفير تجربة تسوق آمنة وموثوقة لجميع الأطراف وقد يشار
        اليها بـ وسيلة الدفع "بنكنوت".
      </p>
      <p>
        <strong style={{ fontSize: '24px', marginBottom: '15px' }}>
          شروط استخدام منصة بنكنوت
        </strong>
        <i style={{ display: 'block' }}>
          مستخدم منصة بنكنوت أنت بإتمامك عملية التسجيل توافق على الشروط والأحكام
          أدناه:
        </i>
        &#9679; سوف يتم مشاركة بعض معلوماتك لمزود الخدمة حسب الحاجة إليها ومن
        ذلك موقعك ورقم جوالك لتسهيل تجربة الشراء
        <br />
        &#9679; عدم الاختراق أو التحايل على قوانين وسياسة وأنظمة المنصة أو أي
        حقوق تتعلق بطرف ثالث
        <br />
        &#9679; عدم نسخ الإعلان من منصة بنكنوت وإعادة نشرها في مواقع أخرى
        <br />
        &#9679; عدم استخدام أي وسيلة غير شرعية للوصول للإعلانات أو بيانات
        المستخدمين الآخرين أو انتهاك لسياسة وحقوق منصة بنكنوت أو الوصول لمحتوى
        المنصة أوتجميع وتحصيل معلومات وبيانات تخص منصة بنكنوت أو عملاءها
        والاستفادة منها بأي شكل من الأشكال أو إعادة نشرها
        <br />
        &#9679; عدم التلاعب بأسعار المنتجات وإلحاق الضرر بالمستخدمين الآخرين.
        <br />
        &#9679; عدم التعرض للسياسات، أو السيادات الدولية، أو الشخصيات المعتبرة،
        أو أي مناقشات غير مشروعة
        <br />
        &#9679; عدم تضمين الإعلان شعاراً أو علامة تجارية لا تملك حق استعمالها
        <br />
        &#9679; عدم انتهاك أنظمة حقوق الانسان في نشر الإعلانات
        <br />
        &#9679; للإعلان العقاري. الالتزام بضوابط الهيئة العامة للعقار
        <br />
        &#9679; الحصول على ترخيص من وزارة السياحة لتقديم اي عروض او منتجات
        سياحية.
        <br />
        &#9679; عدم جمع أو استعمال بيانات مستخدمي المنصة الشخصية أو أرقامهم
        لأغراض غير مصرح لها
        <br />
        &#9679; عدم الإقدام على نشر اعلان من شأنه إلحاق الضرر بسمعة منصة بنكنوت
        أو ترويجه على منصات التواصل الاجتماعي
        <br />
        &#9679; تخلي بنكنوت مسؤوليتها عن أي تواصل بين البائع المشتري بعد انتهاء
        عملية البيع.
      </p>
      <p>
        <strong style={{ fontSize: '24px', marginBottom: '12px' }}>
          رسوم المنصة
        </strong>
        <br />
        استخدام المنصة مجاني، ولكن رسوم المنصة تستحق عند إتمام عملية البيع او
        الشراء باستخدام المنصة.
        <br />
        الرسوم هي <strong>عمولة</strong> من كل عملية بيع لسلعة او منتج او خدمة
        تتم عن طريق المنصة.
        <br />
        تظهر العمولة وقدرها للمستخدم عند القيام بنشر الإعلان، ويلتزم بدفعها
        المعلن أو البائع عند إتمام عملية البيع.
      </p>
      <p>
        <strong style={{ fontSize: '24px', marginBottom: '12px' }}>
          الإعلانات المحظورة والسلع المخالفة على منصة بنكنوت
        </strong>
        <strong
          style={{
            fontSize: '20px',
            display: 'block',
          }}
        >
          &#9726; المواد غير القانونية
        </strong>
        بيع أو شراء <strong>المواد المخدرة </strong>
        أو <strong>العقاقير غير المصرح بها</strong>
        <br />
        <strong
          style={{
            fontSize: '20px',
            display: 'block',
          }}
        >
          &#9726; المنتجات الخطرة أو المقيدة
        </strong>
        الإعلان عن منتجات <strong>التبغ والدخان</strong> (هذه السلع تعرضك
        للمخالفة من قبل الجهات المختصة)
        <br />
        <strong>المواد الكيميائية</strong> الضارة أو القابلة للاشتعال
        <br />
        <strong>الأجهزة الالكترونية الممنوعة:</strong> مثل أجهزة التشويش
        <br />
        منتجات تحتاج إلى <strong>ترخيص خاص</strong> مثل الأدوية والوصفات الطبية
        <br />
        <strong>الألعاب النارية</strong> أو أي منتجات خطرة قد تؤدي لإصابة أو تلف
        <br />
        <strong
          style={{
            fontSize: '20px',
            display: 'block',
          }}
        >
          &#9726; المنتجات المحظورة أخلاقياً أو اجتماعياً
        </strong>
        <strong>المنتجات</strong> الغير لائقة
        <br />
        السلع التي تمثل <strong>إهانة أو إساءة</strong> دينية أو ثقافية
        <br />
        <strong
          style={{
            fontSize: '20px',
            display: 'block',
          }}
        >
          &#9726; الخدمات غير القانونية أو غير الأخلاقية
        </strong>
        <strong>كخدمات التزوير</strong> مثل بيع شهادات مزورة أو وثائق رسمية
        <br />
        <strong
          style={{
            fontSize: '20px',
            display: 'block',
          }}
        >
          &#9726; المنتجات الطبية والصحية
        </strong>
        بيع <strong>الأدوية أو المنتجات الطبية</strong> التي تحتاج إلى وصفة طبية
        أو موافقة صحية
        <br />
        المنتجات التي تحتوي على ادعاءات صحية غير مثبتة أو مضللة
        <br />
        <strong
          style={{
            fontSize: '20px',
            display: 'block',
          }}
        >
          &#9726; بيع الحيوانات
        </strong>
        الحيوانات المهددة بالانقراض أو المحظور بيعها قانونياً
        <br />
        الحيوانات البرية أو الخطرة التي تحتاج لتصاريح خاصة
        <br />
        <strong
          style={{
            fontSize: '20px',
            display: 'block',
          }}
        >
          &#9726; الاحتيال والإعلانات المضللة
        </strong>
        إعلانات تحتوي على معلومات غير صحيحة أو مبالغ فيها لخداع المشترين
        <br />
        المنتجات التي لا يتم شحنها فعلياً أو التي يُقصد بها الاحتيال
        <br />
        <strong
          style={{
            fontSize: '20px',
            display: 'block',
          }}
        >
          &#9726; الأنشطة المالية المشبوهة
        </strong>
        <strong>كالعملات المزيفة</strong> أو الأوراق المالية المزوّرة
        <br />
        <strong
          style={{
            fontSize: '20px',
            display: 'block',
          }}
        >
          &#9726; الإعلانات السياسية أو المثيرة للجدل
        </strong>
        الدعاية السياسية أو التحريض ضد فئات معينة
        <br />
        المحتوى الذي يدعو للكراهية أو التمييز
        <br />
        <strong
          style={{
            fontSize: '20px',
            display: 'block',
          }}
        >
          &#9726; الخدمات الشخصية المحظورة
        </strong>
        إعلانات خدمات <strong>غير قانونية</strong> مثل عروض العمل الوهمية التي
        تكون غرضها الاحتيال
        <br />
        إعلانات التبرع وطلب المساعدة أو التسول
      </p>
      <p>
        <strong style={{ fontSize: '24px', marginBottom: '12px' }}>
          الردود والرسائل الممنوعة
        </strong>
        <br />
        <strong>
          قد يتم حذف بعد الرسائل او التعليقات على المنصة الي تتضمن شي من التالي:
        </strong>
        <br />
        &ndash; السب والشتم
        <br />
        &ndash; الاستهزاء والتهكم على المنصة السلعة او البائع
        <br />
        &ndash; عدم الجدية وعدم الرغبة في الشراء
        <br />
        &ndash; التعليقات التي لا تخص الإعلان
        <br />
        &ndash; التعليقات اللي تحتوى على أي نوع من أنواع الاساءة
      </p>
      <p>
        <strong style={{ fontSize: '24px', marginBottom: '12px' }}>
          إيقاف الحسابات
        </strong>
        <br />
        <i>
          يحق لبنكنوت إيقاف الحساب لاي مستخدم ومنعه من الوصول للمنصة وإعادة فتح
          حسابه، دون الحاجة لإخطاره بذلك وإحالته إلى الجهات المختصة إذا لزم
          الأمر في أحد الحالات التالية:
        </i>
        <br />
        &ndash; يحق لبنكنوت إيقاف أي حساب يحاول العبث أو التحايل في عمليات البيع
        والشراء أو الإضرار بأحد الأطراف
        <br />
        &ndash; يحق لبنكنوت حظر حساب "العميل" في حال عدم الالتزام بدفع القيمة
        المتفق عليها مع "مزود الخدمة" أو " البائع"
        <br />
        &ndash; يحق لبنكنوت إيقاف حساب "العميل" ورفض اكمال عملية الشراء دون
        إبداء أي أسباب.
        <br />
        &ndash; يحق لبنكنوت إيقاف حساب "البائع" أو أحد الشركات المشاركة في حال
        اتضح عدم مصداقيتها.
        <br />
        &ndash; يحق لبنكنوت إيقاف حساب "البائع" أو أحد الشركات المشاركة في حال
        عرض سلع مخالفة أو مشبوهة.
        <br />
        &ndash; يحق لبنكنوت إيقاف حساب أي مستخدم "بائع كان ام مشتري" في حال تم
        استلام بلاغ أو شكوى ضد المستخدم أوفي حال ارتكابه مخالفة ترى إدارة المنصة
        أنها تستحق الإيقاف.
      </p>
      <p>
        <strong style={{ fontSize: '24px', marginBottom: '12px' }}>
          خدمة الضمان | الدفع عن طريق [بنكنوت]
        </strong>
        <br />
        تقدم منصة بنكنوت كأحد خيارات الدفع المتاحة الدفع باستخدام خدمة الضمان
        [بنكنوت] تقوم خدمة الضمان بحماية كل من طرفي المعاملة سواء بائع أو مشتري
        عن طريق تعليق المبلغ المدفوع الى حين إنهاء إجراءات المعاملة وبالشروط
        المتفق عليها بين الأطراف.
      </p>
      <p>
        <strong style={{ fontSize: '24px', marginBottom: '12px' }}>
          رسوم خدمة الضمان
        </strong>
        <br />
        يوافق المشتري على دفع الرسوم الخاصة بخدمة الضمان عند اختياره وسيلة الدفع
        [بنكنوت] لشراء سلعة أو طلب خدمة والتي تقدر بـ ٢٪ من قيمة العملية
        الشرائية.
        <br />
        يوافق جميع المستخدمين على تعليمات الضمان الخاصة بمنصة بنكنوت، بالإضافة
        إلى أي رسوم أخرى، بما في ذلك على سبيل المثال لا الحصر، رسوم خدمات الطرف
        الثالث (على سبيل المثال، الشحن أو ما إلى ذلك).
        <br />
        بمجرد الدفع، تكون رسوم منصة بنكنوت لخدمة الضمان غير قابلة للاسترداد،
        بالإضافة الى ذلك قد تتغير الرسوم من وقت لآخر وفقاً لتقدير المنصة وسوف
        يتم تقديم اشعار المستخدمين في حال حدوث أي تغييرات على الرسوم او سياسة
        الضمان،
      </p>
      <p>
        <strong style={{ fontSize: '24px', marginBottom: '12px' }}>
          النزاعات
        </strong>
        <br />
        يحق للمستخدم سواء البائع أو المشتري أن يقوم بالتصعيد لمنصة بنكنوت عند
        اختيار خدمة الضمان [بنكنوت] كوسيلة دفع في حال حدوث أي خلاف او نزاع.
        <br />
        سيقوم فريق بنكنوت بدوره بالتواصل مع جميع الأطراف لجمع كافة البيانات
        المطلوبة، واستخدام الإجراء اللازم بناء على الحالة والأسباب. قد يترتب على
        التصعيد حل المشكلة بطريقة ودية بين أطراف المعاملة، استكمال المعاملة أو
        إلغائها مع التعويضات أو بدونها حسب قرار فريق تسوية المنازعات
        <br />
        قد تترتب نتيجة التصعيد بعد اطلاع فريق بنكنوت وحسب ما يراه مناسباً على
        كافة التفاصيل على كل من التالي:
        <br />
        &ndash; إعادة المبلغ الى المشتري بعد حسم رسوم خدمة الضمان، بالإضافة إلى
        أي تعويضات -إن وجدت-
        <br />
        &ndash; استكمال عملية تحصيل البائع للمبلغ بعد حسم رسوم خدمة الضمان،
        بالإضافة إلى أي تعويضات -إن وجدت-
        <br />
        &ndash; تحويل جزء من المبلغ للبائع وجزء للمشتري حسب حالة النزاع بعد حسم
        رسوم خدمة الضمان، بالإضافة إلى أي تعويضات -إن وجدت-
      </p>
      <p>
        <strong style={{ fontSize: '24px', marginBottom: '12px' }}>
          الإجراءات المتبعة لتقديم الشكاوى:
        </strong>
        <br />
        ١- عن طريق التواصل بنا من خلال البريد الالكتروني
        <br />
        <a href="mailto:info@banknote.sa">info@banknote.sa</a>
        <br />
        ٢-عبر إحدى حسابات بنكنوت على مواقع التواصل الاجتماعي
        <Grid
          display="grid"
          gridTemplateColumns="repeat(3, 20px)"
          gap={2}
          alignItems="center"
        >
          <Link
            href="https://www.facebook.com/profile.php?id=100085262892258&mibextid=LQQJ4d"
            underline="none"
            target="_blank"
          >
            <img
              src={FACEBOOK_ICON}
              alt="facebook"
              style={{
                width: '30px',
                verticalAlign: 'text-top',
              }}
            />
          </Link>

          <Link
            href="https://twitter.com/usebanknote?s=11&t=u9DUG3xtS38_NqVEnF6n2g"
            underline="none"
            target="_blank"
          >
            <img
              src={TWITTER_ICON}
              alt="twitter"
              style={{
                width: '30px',
                verticalAlign: 'text-top',
              }}
            />
          </Link>

          <Link
            href="https://www.instagram.com/usebanknote/?igshid=YmMyMTA2M2Y%3D"
            underline="none"
            target="_blank"
          >
            <img
              src={INSTAGRAM_ICON}
              alt="instagram"
              style={{
                width: '30px',
                verticalAlign: 'text-top',
              }}
            />
          </Link>
        </Grid>
      </p>
      <p>
        <strong style={{ fontSize: '24px', marginBottom: '12px' }}>
          حقوق النشر
        </strong>
        <br />
        يعتبر المحتوى والإعلانات الموجودة في تطبيق او منصة بنكنوت ملك لشركة
        بنكنوت [ورقة نقدية لتقنية المعلومات] ويمنع منعاً مطلقاً نسخ، إعادة
        توزيع، استخدام، أو طبع أي من هذه المواد أو أي جزء منها من "المنصة"، إلا
        في الحدود التي تسمح بها القوانين السارية في المملكة العربية السعودية في
        هذا الشأن.
        <br />
        إذا كان لديكم أي استفسارات أو ملاحظات أو طلبات متعلقة بشروط وأحكام
        استخدام المنصة، أو إذا كان لديكم أي شكاوى أو اعتراضات فيما يتعلق بطريقة
        معالجة بنكنوت لبياناتكم الشخصية، الرجاء التواصل معنا على البريد
        الإلكتروني
        <br />
        <a href="mailto:info@banknote.sa">info@banknote.sa</a>
      </p>
      <br />
      <br />
    </>
  );
}
