export function WhoWeAre() {
  return (
    <>
      <h2 style={{ color: '#0557a9', marginBottom: 0, fontSize: '32px' }}>
        من نحن
      </h2>
      <p style={{ marginTop: 0 }}>بنكنوت منصة بيع وشراء تفهمـك وتحميـــك</p>
      <p>
        <strong style={{ fontSize: '24px', marginBottom: '12px' }}>بائع</strong>
        <br />
        انشر اعلانك <strong>مجانـاً</strong> وبخطوات بسيطة
        <br />
        اعرض منتجك أو خدمتك <strong>لعملاء أكثر</strong>
        <br />
        عند تقديم خدماتك <u>اضمن حقك</u> باختيار وسيلة الدفع [بنكنوت]
        <strong> لتحصيل مضمون</strong>
      </p>
      <p>
        <strong style={{ fontSize: '24px', marginBottom: '12px' }}>
          مشتري
        </strong>
        <br />
        تصفح المنتجات وتواصل مع البائعين بكل <strong>آمـان </strong>
        <u>داخل التطبيق</u>
        <br />
        اختار <strong>وسيلة الدفع المناسبة</strong> لك، جميع وسائل الدفع متوفرة
        داخل التطبيق
        <br />
        جرّب <strong>خدمة الضمان</strong> [ بنكنوت ] لحمايتك من أي عملية
        <strong> احتيال</strong> محتملة
      </p>
      <p>
        عند اختيارك خدمة الضمان [بنكنوت] كوسيلة دفع راح يتم تعليق المبلغ الى حين
        وصول المنتج و التأكد من سلامته ومطابقته المعايير المتفق عليها
      </p>
      <br />
      <br />
    </>
  );
}
