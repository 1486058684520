export function PrivacyPolicy() {
  return (
    <>
      <h2 style={{ color: '#0557a9', marginBottom: 0, fontSize: '32px' }}>
        سياسة الخصوصية لمنصة بنكنوت
      </h2>
      <p>
        <strong style={{ fontSize: '24px', marginBottom: '12px' }}>
          مقدمة
        </strong>
        <br />
        في بنكنوت نحن نقدر اهتمامك بخصوصية بياناتك. لقد تم إعداد هذه السياسة
        لمساعدتك على فهم البيانات التي نجمعها منك عند زيارتك لمنصة
        <strong> بنكنوت</strong> وكيفية تعاملنا مع هذه البيانات بالإضافة الى
        شروط الاستخدام وتوضيح لإجراءات المتبعة لبعض الحالات.
      </p>
      <p>
        <strong style={{ fontSize: '24px', marginBottom: '12px' }}>
          سياسة الخصوصية
        </strong>
        <br />
        عميلنا العزيز أنت بإتمام عملية التسجيل توافق على هذه الشروط والأحكام
        وتوافق على سياسة الخصوصية الخاصة في منصة بنكنوت في جمع واستخدام البيانات
        الخاصة بك المسجلة على تطبيق بنكنوت أو الموقع الالكتروني أو أي طرف ثالث
        من مقدمي الخدمة سواءً قمت بالاطلاع عليها أو تخطيتها في خطوات التسجيل.
      </p>
      <p>
        <strong style={{ fontSize: '24px', marginBottom: '12px' }}>
          جمع المعلومات
        </strong>
        <br />
        نحن نقوم بجمع المعلومات التي تقدمها لنا مباشرة من خلال وصولك أو استخدام
        المنصة، مثلا، عندما تقوم بإنشاء أو تعديل الحساب الخاص بك، نشر اعلان،
        تصفح الاعلانات، الاتصال بمركز العناية بالعملاء أو الاتصال بنا بطرق أخرى.
        <br />
        يمكن أن تتضمن هذه المعلومات: اسمك، تاريخ ميلادك، الجنس، البريد
        الإلكتروني، رقم الجوال أو الهاتف، العنوان البريدي، صورة الملف الشخصي،
        معلومات النظام المستخدم، المعلومات الديموغرافية، طرق الدفع، البيانات
        المالية ومعلومات بطاقة الائتمان، وأي معلومات أخرى.
      </p>
      <p>
        <strong style={{ fontSize: '24px', marginBottom: '12px' }}>
          معلومات نجمعها من مصادر أخرى
        </strong>
        <br />
        ربما نحصل على معلومات أيضاً من مصادر أخرى وندمجها مع المعلومات الشخصية
        التي تلقيناها منك أو جمعناها من خلال خدماتنا. على سبيل المثال:
        <br />
        إذا اخترت ربط أو إنشاء أو تسجيل الدخول إلى حساب بنكنوت الخاص بك مع مزود
        خدمات الدفع أو خدمة وسيلة تواصل اجتماعية على سبيل المثال الدخول عن طريق
        بريدك الجيميل أو إذا كنت ترتبط من خلال تطبيق أو موقع منفصل يستخدم واجهة
        معالجة التطبيق الخاصة بنا (أو واجهة معالجة التطبيق التي نستخدمها)،
        يمكننا الحصول على معلومات عنك أو عن اتصالاتك من ذلك الموقع أو التطبيق.
        <br />
        أيضاً، عندما تقوم بعملية شراء من المنصة، يجوز للبائع أو مقدم الخدمة
        التابع لنا تزويدنا بتقييم للمستخدم بعد إتمام عملية البيع او تقديم الخدمة
        لك.
      </p>
      <p>
        <strong style={{ fontSize: '24px', marginBottom: '12px' }}>
          استخدام البيانات الشخصية
        </strong>
        <br />
        يمكننا استخدام المعلومات الشخصية التي تزودنا بها التي نجمعها منك للأغراض
        التالية:
        <br />
        &ndash; تحسين الخدمات المقدمة، يشمل ذلك على سبيل المثال، إرسال الإيصالات
        وتقديم المنتجات أو الخدمات التي تطلبها (وإرسال معلومات ذات صلة)، تطوير
        ميزات جديدة، تقديم دعم الدعم لمستخدمي المنصة، وتطوير ميزات الأمان، ومنح
        الإذن للمستخدمين، وإرسال ترقيات وتحديثات المنتج والرسائل الإدارية.
        <br />
        &ndash; أداء العمليات والإدارة الداخلية، يشمل ذلك، على سبيل المثال، منع
        الاحتيال وإساءة استخدام المنصة، واستكشاف أخطاء البرمجيات وحلولها
        والمشاكل التشغيلية، وإجراء تحليل البيانات، والاختبار والبحث ومراقبة
        وتحليل اتجاهات الاستخدام والنشاط.
        <br />
        &ndash; إرسال معلومات لك نرى بأنها ذات أهمية بالنسبة لك، يشمل ذلك
        معلومات حول منتجات تهمك أو خدمات بنكنوت الجديدة والعروض الترويجية وأحدث
        الأخبار لمنصة بنكنوت وشركات أخرى، بحسب المسموح وطبقاً للقوانين المحلية
        المعمول بها، ومعالجة المسابقات أو أي مشاركات ترويجية أخرى والوفاء بأي
        جوائز ذات صلة؛
        <br />
        &ndash; إشعارك بشأن التغيرات التي قد تطرأ على المنصة
        <br />
        &ndash; السماح لك بالمشاركة في ميزات تفاعلية خاصة بالمنصة
        <br />
        &ndash; تخصيص وتحسين الخدمات، يشمل ذلك تقديم الميزات أو التوصية بها
        والمحتوى، والاتصالات الاجتماعية، والإحالات، والإعلانات.
      </p>
      <p>
        <strong style={{ fontSize: '24px', marginBottom: '12px' }}>
          تخزين ونقل البيانات الشخصية
        </strong>
        <br />
        أنت توافق على أننا نمتلك الحق في نقل البيانات الشخصية المبينة في سياسة
        الخصوصية هذه من وإلى، ومعالجتها وحفظها في الخوادم الخاصة بنا. وسوف نقوم
        باتخاذ التدابير اللازمة لحماية بياناتك الشخصية وفقاً لسياسة الخصوصية
        هذه. بتقديم البيانات الشخصية الخاصة بك، فإنك توافق على هذا النقل أو
        التخزين أو المعالجة. سوف نقوم باتخاذ كافة الخطوات اللازمة والمعقولة
        لضمان معالجة البيانات الخاصة بك على نحو آمن ووفقا لسياسة الخصوصية هذه.
        <br />
        فيما يتعلق بالبيانات الشخصية المعالجة والمحفوظة والمنقولة من وإلى
        الخوادم الخاصة بنا، تكون بنكنوت هي المتحكم بالبيانات لغرض الامتثال
        لقوانين حماية البيانات في المملكة العربية السعودية
        <br />
        للحفاظ على سلامة قواعد البيانات الخاصة بنا، ولأداء الخدمات على أساس
        مستمر نيابة عنك، ولأغراض البحث والتحليلات ولأغراض إحصائية ولضمان
        الامتثال للقوانين واللوائح التنظيمية المطبقة، نحن نحتفظ بالمعلومات
        الشخصية والمعلومات الديموغرافية المقدمة بواسطة المستخدمين لفترة طويلة من
        الوقت. لن تكون بنكنوت مسؤولة عن دقة المعلومات التي تقدمها، وستقوم بتعديل
        وتحديث معلوماتك الشخصية في قواعد البيانات الخاصة بنا بناء على طلبك. سوف
        نقوم بمسح أو أرشفة معلوماتك الشخصية من الاستخدام النشط إذا طُلب ذلك
        بمقتضى قانون معين أو بطلب منك. بالدخول أو الوصول إلى الخدمات، فإنك
        بموجبه تضمن وتتعهد بأنك تفهم أن جميع المعلومات المقدمة بواسطتك ومن خلال
        الخدمات أو خلاف ذلك إلى المنصة يمكن استخدامها بواسطة بنكنوت وفقا
        للقوانين المطبقة، وسياسة الخصوصية هذه وشروط وأحكام الاستخدام للعميل.
      </p>
      <p>
        <strong style={{ fontSize: '24px', marginBottom: '12px' }}>
          مشاركة البيانات الشخصية
        </strong>
        <br />
        ربما تقوم بنكنوت بمشاركة البيانات الشخصية التي تجمعها عنك كما هو موضح في
        هذا البيان أو كما هو موضح في وقت جمع المعلومات أو مشاركتها، يشمل ذلك ما
        يلي: من خلال خدماتنا يمكننا مشاركة البيانات الشخصية الخاصة بك:
        <br />
        &ndash; مع الطرف الآخر من العملية الشرائية (بائع أو مشتري) على سبيل
        المثال، نقوم بمشاركة اسمك ورقم جوالك مع الطرف الآخر لغرض التواصل او
        إتمام عملية البيع أو الشراء
        <br />
        &ndash; مع أطراف ثالثة لتقديم خدمة قمتم بطلبها من خلال شراكة أو عرض
        ترويجي مقدم بواسطة طرف ثالث أو بواسطتنا.
        <br />
        &ndash; مع أطراف ثالثة تختار أنت السماح لنا بمشاركة البيانات الشخصية
        الخاصة بك معها، على سبيل المثال، تطبيقات أو مواقع أخرى تندمج مع واجهة
        معالجة التطبيق (API) أو الخدمات الخاصة بنا، أو واجهة معالجة تطبيق أو
        خدمة نندمج معها.
        <br />
        &ndash; مع جهة العمل الخاصة بك وأي أطراف ثالثة لازمة مشاركة بواسطتنا أو
        بواسطة جهة العمل الخاصة بكم، إذا كنت مشاركاً في أي من الحلول المؤسسية
        الخاصة بنا.
      </p>
      <p>
        <strong style={{ fontSize: '24px', marginBottom: '12px' }}>
          التسويق واختيار الرفض
        </strong>
        <br />
        ربما تقوم بنكنوت بمشاركة البيانات التي تجمعها عنك لأغراض تسويقية سواء
        لبنكنوت ام مع أحد الشركاء، بالتالي يمكن لك رفض الاتصالات الواردة من
        قبلنا أو من قبل شركائنا بغرض التسويق ويكون لك الخيار في طلب عدم استخدام
        بياناتك الشخصية وطلب حذفها من قاعدة بياناتنا.
      </p>
      <p>
        <strong style={{ fontSize: '24px', marginBottom: '12px' }}>
          التخصيص
        </strong>
        <br />
        قد نقوم بجمع تفضيلاتك من تسجيلات الإعجاب وسجل البحث والطلبات المرسلة
        لتزويدك بتجربة أفضل وأكثر تخصيصًا.
      </p>
      <p>
        <strong style={{ fontSize: '24px', marginBottom: '12px' }}>
          التتبع
        </strong>
        <br />
        قد تقوم بنكنوت بتتبع نشاط المستخدم في المنصة؛ مثل استخدام كلمات البحث،
        تصفح الإعلانات وعدد المحادثات المفتوحة في المنصة.
      </p>
      <p>
        <strong style={{ fontSize: '24px', marginBottom: '12px' }}>
          إفشاء المعلومات
        </strong>
        <br />
        يمكننا مشاركة البيانات الشخصية الخاصة بك:
        <br />
        ١- مع الكيانات التابعة والفرعية لمنصة بنكنوت، مع الوكلاء والاستشاريين
        وشركاء التسويق والإعلان ومزودي خدمات آخرين ممن يحتاجون إلى الوصول إلى
        تلك البيانات الشخصية لأداء عمل نيابة عنا؛ أو لتنفيذ أي عقد نبرمه معهم.
        <br />
        ٢-استجابة لطلب أي معلومات بواسطة سلطة مختصة إذا كنا نرى بأن الكشف عن تلك
        المعلومات كان بموجب، أو خلاف ذلك، بمقتضى أي قانون أو لائحة تنظيمية أو
        إجراء قانوني معمول به.
        <br />
        ٣-مع الجهات المسؤولة المكلفة بإنفاذ القانون والسلطات الحكومية أو أطراف
        ثالثة أخرى لنفي أو تطبيق الشروط والأحكام الخاصة بك وللتحقيق في انتهاكات
        محتملة أو إذا كنا نرى بأن أعمالكم كانت مخالفة لشروط وأحكام الخدمة الخاصة
        بنا أو لحماية الحقوق والممتلكات وأمان بنكنوت أو الغير
        <br />
        ٤-مع أطراف ثالثة فيما يخص أو خلال المفاوضات بشأن أي عملية اندماج، أو بيع
        لأصول الشركة، أو توحيد أو إعادة هيكلة أو تمويل أو شراء حصة أو كامل
        شركتنا بواسطة أي شركة أخرى أو شرائنا لأي شركة أخرى.
      </p>
      <p>
        <strong style={{ fontSize: '24px', marginBottom: '12px' }}>
          الوصول إلى المعلومات
        </strong>
        <br />
        لك الحق في الوصول إلى المعلومات المحفوظة عنك، ولك الحق في اجراء
        التغييرات عليها في جميع الأوقات. يمكنك تعديل معلوماتك الشخصية في أي وقت
        عن طريق تسجيل الدخول إلى الحساب وتحرير المعلومات المطلوبة.
      </p>
      <p>
        <strong style={{ fontSize: '24px', marginBottom: '12px' }}>
          الاحتياطات الأمنية
        </strong>
        <br />
        الملف الشخصي الخاص بك لدى بنكنوت محمي بحيث يكون لك الحق ولموظفي بنكنوت
        المخولين في الوصول إلى معلومات الحساب الخاص بك. ولن يقوم موظفو بنكنوت
        وبصورة استباقية بالاتصال بك وطلب أي معلومات حساب شخصية، يشمل ذلك كلمة
        المرور الخاصة بك.
        <br />
        تبذل بنكنوت كل جهد مستطاع لضمان أمن وحماية بياناتك الشخصية في نظام
        الشركة. موظفو بنكنوت مكرسون للحفاظ على سياسة الخصوصية المنصوص عليها هنا
        وأي مبادرات خصوصية أخرى، والمراجعة الدورية لأمن الويب والتأكد من أن كل
        موظف لدى بنكنوت ملتزم بسياسية الخصوصية وسرية المعلومات. مع ذلك للأسف لا
        يمكن ضمان حماية أي بيانات تنقل عبر الإنترنت بنسبة 100%. نتيجة لذلك، لا
        يمكن لبنكنوت ضمان أمان أي بيانات شخصية تنقلها أو ترسلها لنا وأنت تقوم
        بذلك على عاتقك الشخصي. بالتالي تخلي منصة بنكنوت مسؤوليتها ضد أي مطالبة
        أو مسؤولية ربما تنشأ في حال حصول أي أطراف على البيانات الشخصية التي
        ترسلها إلى بنكنوت.
      </p>
      <p>
        <strong style={{ fontSize: '24px', marginBottom: '12px' }}>
          التعديلات على سياسة الخصوصية
        </strong>
        <br />
        لبنكنوت الحق في تعديل بنود وشروط سياسة سرية وخصوصية المعلومات إن لزم
        الأمر ومتى كان ذلك ملائماً. في حال حدوث أي تعديل سيتم اخطار مستخدمي
        بنكنوت عن طريق البيانات التي حصلنا عليها.
        <br />
        استخدامكم للمنصة بعد ذلك الإشعار يشكل موافقة من طرفكم على تلك التغييرات.
      </p>
      <p>
        <strong style={{ fontSize: '24px', marginBottom: '12px' }}>
          اتصل بنا
        </strong>
        <br />
        يمكنك التواصل معنا في حال رغبتك في معرفة أكثر عن سياسة الخصوصية الخاصة
        بنا عن طريق ارسال بريد الكتروني على العنوان التالي:
        <br />
        <a href="mailto:info@banknote.sa">info@banknote.sa</a>
      </p>
      <br />
      <br />
    </>
  );
}
