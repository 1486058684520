import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';
import ReactGA from 'react-ga4';
import { theme } from 'components/theme';
import { Layout } from 'components/layout';
import { IRoutes } from 'components/layout/Layout.types';
import {
  Home,
  UpsertDeal,
  Profile,
  AboutUs,
  Faq,
  WhoWeAre,
  PrivacyPolicy,
  TermsAndConditions,
} from 'views';
import { ScrollToTop } from 'components';
import { firebaseConfig } from '../firebaseconfig';
import { BankNoteRoutes } from './BankNoteRoutes';
import './assests/css/style.css';

ReactGA.initialize('AW-11310753999');

ReactGA.gtag('config', 'AW-11310753999');

const queryClient = new QueryClient();

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);

function App() {
  const routes: IRoutes[] = [
    {
      name: 'الرئيسية',
      path: '/',
      component: <Home />,
    },
    {
      name: 'من نحن',
      path: '/WhoWeAre',
      component: <WhoWeAre />,
    },
    {
      name: 'تعرّف على بنكنوت',
      path: '/about',
      component: <AboutUs />,
    },
    {
      name: 'الأسئلة الشائعة',
      path: '/faq',
      component: <Faq />,
    },
    {
      name: 'سياسة الخصوصية',
      path: '/PrivacyPolicy',
      component: <PrivacyPolicy />,
    },
    {
      name: 'الشروط والأحكام',
      path: '/TermsAndConditions',
      component: <TermsAndConditions />,
    },
  ];

  return (
    <SnackbarProvider maxSnack={3}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <ScrollToTop>
              <Layout routes={routes}>
                <Routes>
                  {routes.map((route) => (
                    <Route
                      key={route.name}
                      path={route.path}
                      element={route.component}
                    />
                  ))}

                  <Route
                    path={BankNoteRoutes.upsertDeal}
                    element={<UpsertDeal />}
                  />
                  <Route
                    path={`${BankNoteRoutes.profile}/*`}
                    element={<Profile />}
                  />

                  <Route
                    path="*"
                    element={<Navigate to={BankNoteRoutes.base} />}
                  />
                </Routes>
              </Layout>
            </ScrollToTop>
          </BrowserRouter>
        </ThemeProvider>
      </QueryClientProvider>
    </SnackbarProvider>
  );
}

export default App;
