import {
  AppBar,
  Box,
  Button,
  Container,
  Toolbar,
  Grid,
  Dialog,
  IconButton,
  Menu,
  MenuItem,
  DialogTitle,
  useTheme,
  useMediaQuery,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { AccountCircle, Close, Menu as MenuIcon } from '@mui/icons-material';
import Cookies from 'js-cookie';
import LOGO from 'assests/images/logo-svg.svg';
import DARKLOGO from 'assests/images/logo.svg';
import { BankNoteRoutes } from 'BankNoteRoutes';
import { Login, Totp } from 'views';
import { Footer } from '../footer';
import { ILayout } from './Layout.types';

const drawerWidth = 240;

export function Layout({ children, routes }: ILayout) {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);
  const [isTotpDialogOpen, setIsTotpDialogOpen] = useState(false);

  const [userMenuElement, setUserMenuElement] = useState<null | HTMLElement>(
    null,
  );

  const isUserLoggedIn = Cookies.get('token');

  function onCloseLoginDialog() {
    setIsLoginDialogOpen(false);
  }

  // function onLogin() {
  //   setIsLoginDialogOpen(true);
  // }

  // function onCreateDeal() {
  //   if (isUserLoggedIn) {
  //     navigate(BankNoteRoutes.upsertDeal);
  //   } else {
  //     setIsLoginDialogOpen(true);
  //   }
  // }

  function openTotpDialog() {
    setIsTotpDialogOpen(true);
    onCloseLoginDialog();
  }

  function onCloseTotpDialog() {
    setIsTotpDialogOpen(false);
  }

  function onToggleUserMenu(event: React.MouseEvent<HTMLElement>) {
    setUserMenuElement(event.currentTarget);
  }

  function onCloseUserMenu() {
    setUserMenuElement(null);
  }

  function onLogout() {
    setUserMenuElement(null);

    Cookies.remove('token');
    Cookies.remove('accountName');
    Cookies.remove('accountMobileNumber');
    Cookies.remove('accountId');
    Cookies.remove('accountBalance');
    Cookies.remove('accountRate');

    navigate(BankNoteRoutes.base);
  }

  useEffect(() => {
    if (!location.pathname.includes(BankNoteRoutes.upsertDeal)) {
      localStorage.removeItem('upsert-deal-step-1');
      localStorage.removeItem('upsert-deal-step-2');
    }
  }, [location]);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <img
        src={DARKLOGO}
        width={120}
        alt="logo"
        style={{ cursor: 'pointer', margin: '12px 0 8px 0' }}
        onClick={() => navigate(BankNoteRoutes.base)}
      />
      <Divider />
      <List sx={{ px: 3 }}>
        {routes.map((route) => (
          <ListItem key={route.name} disablePadding>
            <NavLink
              to={route.path}
              style={{ textDecoration: 'none', width: '100%' }}
            >
              <ListItemButton
                sx={{
                  textAlign: 'center',
                  color: 'primary.main',
                  display: 'block',
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}
              >
                <ListItemText primary={route.name} />
              </ListItemButton>
            </NavLink>
          </ListItem>
        ))}
        {/* {!isUserLoggedIn && (
          <Button
            variant="outlined"
            className="blueBtn outline"
            color="primary"
            sx={{ mt: 4, width: '100%' }}
            onClick={onLogin}
          >
            تسجيل الدخول
          </Button>
        )} */}
        {isUserLoggedIn && (
          <NavLink
            to={`${BankNoteRoutes.profile}/deals`}
            style={{ textDecoration: 'none', width: '100%' }}
          >
            <Button
              variant="outlined"
              color="primary"
              className="orangeBtn contained"
              sx={{ mt: 4, width: '100%' }}
            >
              الاتفاقيات
            </Button>
          </NavLink>
        )}
        {/* {!location.pathname.includes(BankNoteRoutes.upsertDeal) && (
          <Button
            variant="contained"
            className="orangeBtn contained"
            color="primary"
            sx={{ mt: 2, width: '100%' }}
            onClick={onCreateDeal}
          >
            ابدأ اتفاقية جديدة
          </Button>
        )} */}
      </List>
    </Box>
  );
  const container = document.body;

  return (
    <>
      <Container
        className={`page_container ${location.pathname.replace('/', '')}`}
      >
        <Box sx={{ display: 'flex' }}>
          <AppBar sx={{ backgroundColor: '#0557a9', boxShadow: 'none' }}>
            <Container className="page_container">
              <Toolbar
                disableGutters
                sx={{
                  justifyContent: { xs: 'space-between', md: '' },
                }}
              >
                <img
                  className="header_logo"
                  src={LOGO}
                  width={120}
                  alt="logo"
                  style={{ cursor: 'pointer' }}
                  onClick={() => navigate(BankNoteRoutes.base)}
                />
                <Box width={120} />
                <Grid
                  sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
                  gap={1}
                >
                  {routes.map((route) => (
                    <NavLink
                      key={route.name}
                      to={route.path}
                      style={{ textDecoration: 'none' }}
                    >
                      <Button
                        sx={{
                          my: 2,
                          color: 'white.main',
                          display: 'block',
                          fontSize: '16px',
                          fontWeight: 'bold',
                        }}
                      >
                        {route.name}
                      </Button>
                    </NavLink>
                  ))}
                </Grid>
                <Grid
                  sx={{ display: 'flex', flexGrow: 1 }}
                  gap={1}
                  justifyContent="end"
                  alignItems="center"
                >
                  {/* {!location.pathname.includes(BankNoteRoutes.upsertDeal) && (
                    <Button
                      variant="contained"
                      className="orangeBtn contained"
                      sx={{ my: 2, display: { xs: 'none', md: 'block' } }}
                      onClick={onCreateDeal}
                    >
                      ابدأ اتفاقية جديدة
                    </Button>
                  )} */}
                  {/* {!isUserLoggedIn && (
                    <Button
                      variant="outlined"
                      className="orangeBtn outline"
                      sx={{ my: 2, display: { xs: 'none', md: 'block' } }}
                      onClick={onLogin}
                    >
                      تسجيل الدخول
                    </Button>
                  )} */}
                  {isUserLoggedIn && false && (
                    <>
                      <IconButton
                        size="medium"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={onToggleUserMenu}
                        color="primary"
                        className="user_account_icon"
                      >
                        <AccountCircle fontSize="large" />
                      </IconButton>
                      <Menu
                        id="menu-appbar"
                        anchorEl={userMenuElement}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        open={Boolean(userMenuElement)}
                        onClose={onCloseUserMenu}
                      >
                        <MenuItem
                          color="primary"
                          onClick={() => {
                            navigate(`${BankNoteRoutes.profile}/deals`);
                            onCloseUserMenu();
                          }}
                          sx={{ fontSize: '14px' }}
                        >
                          الاتفاقيات
                        </MenuItem>
                        <MenuItem
                          color="primary"
                          onClick={() => {
                            navigate(BankNoteRoutes.profile);
                            onCloseUserMenu();
                          }}
                          sx={{ fontSize: '14px' }}
                        >
                          الملف الشخصي
                        </MenuItem>
                        <MenuItem
                          color="primary"
                          onClick={onLogout}
                          sx={{ fontSize: '14px' }}
                        >
                          تسجيل الخروج
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                </Grid>
                <IconButton
                  className="header_mobile_btn"
                  color="primary"
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  sx={{ display: { md: 'none' } }}
                >
                  <MenuIcon />
                </IconButton>
              </Toolbar>
              <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                SlideProps={{
                  direction: theme.direction === 'rtl' ? 'right' : 'left',
                }}
                sx={{
                  display: { xs: 'block', md: 'none' },
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: drawerWidth,
                  },
                }}
              >
                {drawer}
              </Drawer>
            </Container>
          </AppBar>

          {isLoginDialogOpen && (
            <Dialog
              open={isLoginDialogOpen}
              onClose={onCloseLoginDialog}
              aria-labelledby="login-dialog"
              maxWidth="md"
              fullScreen={fullScreen}
            >
              <DialogTitle id="login-dialog-close-btn" sx={{ px: 1, pb: 0 }}>
                <IconButton onClick={onCloseLoginDialog}>
                  <Close />
                </IconButton>
              </DialogTitle>
              <Login
                isTotpDialogOpen={isTotpDialogOpen}
                openTotpDialog={openTotpDialog}
              />
            </Dialog>
          )}

          <Dialog
            open={isTotpDialogOpen}
            onClose={onCloseTotpDialog}
            aria-labelledby="totp-dialog"
            maxWidth="md"
            fullScreen={fullScreen}
          >
            <DialogTitle id="totp-dialog-close-btn" sx={{ px: 1, pb: 0 }}>
              <IconButton onClick={onCloseTotpDialog}>
                <Close />
              </IconButton>
            </DialogTitle>
            <Totp />
          </Dialog>
        </Box>
        <Box>
          <Box sx={{ pt: 1 }}>
            <Toolbar />
            {children}
          </Box>
        </Box>
      </Container>
      <Container className="page_container footer_container">
        <Box sx={{ pt: 1 }}>
          <Box
            sx={{
              margin: { xs: '0 20px', sm: '0 40px', md: '0 80px' },
              marginTop: { xs: '20px', sm: '40px', md: '80px' },
            }}
            className="footer_wrap"
          >
            <Footer />
          </Box>
        </Box>
      </Container>
    </>
  );
}
